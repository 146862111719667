<template>
    <v-container>

        <v-card class="rounded-lg overflow-hidden" elevation="1">
            <v-card-title flat>

                <v-icon left color="primary">mdi-cash-sync</v-icon>
                Ingresos y Gastos

            </v-card-title>
            <v-divider />

            <v-tabs v-model="tab" :show-arrows="false">
                <v-tab>
                    <v-icon left color="primary" small>mdi-history</v-icon>
                    Historico
                </v-tab>
                <v-tab>
                    <v-icon left color="success" small>mdi-arrow-down</v-icon>
                    Ingresos
                </v-tab>

                <v-tab>
                    <v-icon left color="error" small>mdi-arrow-up</v-icon>
                    Gastos
                </v-tab>


                <v-tab-item>
                    <v-divider />

                    <!-- Fetching records -->

                    <v-data-table :headers="headers" :items="entries" class="elevation-1" :loading="loading"
                        mobile-breakpoint="0" @click:row="editEntry">

                        <template v-slot:top>
                            <v-toolbar flat class="px-2">
                                <v-menu v-model="menu3" :close-on-content-click="false" :nudge-right="40"
                                    max-width="330" min-width="330">
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-text-field dense single-line filled rounded class="rounded-lg my-2"
                                            hide-details="" prepend-inner-icon="mdi-calendar" v-model="dates"
                                            label="Seleccionar fecha" readonly v-bind="attrs" v-on="on"></v-text-field>
                                    </template>
                                    <v-date-picker v-model="dates" range width="100%" scrollable
                                        :min="dates.length > 0 ? dates[0] : null">
                                        <v-spacer></v-spacer>
                                        <v-btn color="primary darken-1" @click="dates = []">Cancelar</v-btn>
                                        <v-btn color="primary darken-1"
                                            @click="fetchRecords(); menu3 = false;">Confirmar</v-btn>

                                    </v-date-picker>
                                </v-menu>
                                <v-spacer></v-spacer>
                                <v-btn class="rounded-lg" color="primary" @click="downloadCSVReport"
                                    v-if="entries.length > 0">
                                    <v-icon :left="!$vuetify.breakpoint.smAndDown">mdi-file-download</v-icon>
                                    <template v-if="!$vuetify.breakpoint.smAndDown">
                                        Descargar reporte
                                    </template>
                                </v-btn>
                            </v-toolbar>
                        </template>

                        <template v-slot:item.type="{ item }"><v-icon
                                :color="item.type === 'ingress' ? 'success' : 'error'">{{ item.type === 'ingress' ?
                                    'mdi-arrow-down' : 'mdi-arrow-up' }}</v-icon></template>


                        <template v-slot:item.date="{ item }">{{ new Date(item.date.seconds * 1000).toLocaleDateString()
                        }}</template>


                        <template v-slot:item.payAmount="{ item }">{{ `${item.currency}
                            ${parseFloat(item.payAmount).toFixed(2)}` }}</template>

                        <template v-slot:item.actions="{ item }">
                            <v-btn color="error" x-small fab dark @click.stop="deleteEntry(item.id)">
                                <v-icon>mdi-delete</v-icon>
                            </v-btn>
                        </template>

                    </v-data-table>
                </v-tab-item>


                <v-tab-item>
                    <v-divider />

                    <v-row no-gutters>
                        <v-col cols="12" md="12">
                            <!-- Enhanced Form -->
                            <v-form @submit.prevent="addEntry" ref="form">
                                <v-row no-gutters>
                                    <v-col cols="12" class="hidden-sm-and-down">
                                        <v-toolbar flat class="pl-4">
                                            Registro de {{ tab === 1 ? 'Ingreso' : 'Gasto' }}

                                            <v-spacer></v-spacer>
                                            <v-btn color="error" @click="resetForm" :loading="loading" class="mr-2">
                                                <v-icon left>
                                                    mdi-broom
                                                </v-icon>
                                                Limpiar
                                            </v-btn>
                                            <v-btn color="success" @click="addEntry" :loading="loading">
                                                <v-icon left>mdi-plus</v-icon>
                                                {{ entry.id ? 'Actualizar' : 'Agregar' }}
                                                {{ tab === 1 ? 'Ingreso' : 'Gasto' }}
                                            </v-btn>
                                        </v-toolbar>
                                    </v-col>
                                    <v-col cols="6" md="4">

                                        <v-menu v-model="menu" :close-on-content-click="false" :nudge-right="40"
                                            transition="scale-transition" offset-y>
                                            <template v-slot:activator="{ on }">
                                                <v-text-field hide-details="" v-model="entry.date" label="Fecha"
                                                    readonly filled v-on="on"></v-text-field>
                                            </template>
                                            <v-date-picker v-model="entry.date" no-title scrollable>
                                                <template v-slot:day="{ date }">
                                                    {{ date.getDate() }}
                                                </template>
                                            </v-date-picker>
                                        </v-menu>
                                    </v-col> <v-divider vertical></v-divider>



                                    <v-col cols="6" md="4"> <v-combobox hide-details="" v-model="entry.currency"
                                            :items="currencies" label="Moneda" filled :search-input.sync="search"
                                            :rules="[rules.required]">
                                            <template v-slot:no-data>
                                                <v-list-item>
                                                    <v-list-item-content>
                                                        <v-list-item-title>
                                                            No hay resultado para "<strong>{{ search }}</strong>".
                                                            Presione
                                                            <kbd>enter</kbd> para agregar.
                                                        </v-list-item-title>
                                                    </v-list-item-content>
                                                </v-list-item>
                                            </template>

                                        </v-combobox></v-col>
                                    <v-divider vertical class="hidden-sm-and-down"></v-divider>
                                    <v-col cols="12" md="4">
                                        <v-combobox filled hide-details="" v-model="entry.category" :items="categories"
                                            label="Categoría" :search-input.sync="search2" :rules="[rules.required]">
                                            <template v-slot:no-data>
                                                <v-list-item>
                                                    <v-list-item-content>
                                                        <v-list-item-title>
                                                            No hay resultado para "<strong>{{ search2 }}</strong>".
                                                            Presione
                                                            <kbd>enter</kbd> para agregar.
                                                        </v-list-item-title>
                                                    </v-list-item-content>

                                                </v-list-item>
                                            </template>
                                        </v-combobox>
                                    </v-col>

                                    <v-col cols="6" md="4"><v-text-field hide-details="" v-model="entry.amount"
                                            label="Cantidad" type="number" filled
                                            :rules="[rules.required, rules.positiveNumber]"></v-text-field></v-col>

                                    <v-divider vertical></v-divider>


                                    <v-col cols="6" md="4"><v-text-field hide-details="" v-model="entry.payAmount"
                                            label="Monto" type="number" prefix="$" filled
                                            :rules="[rules.required, rules.positiveNumber]"></v-text-field></v-col>
                                    <v-divider vertical class="hidden-sm-and-down"></v-divider>

                                    <v-col cols="12" md="4"> <v-text-field hide-details="" v-model="entry.description"
                                            label="Descripción (opcional)" filled></v-text-field>
                                    </v-col>
                                    <v-col cols="12" md="4"> <v-text-field hide-details="" v-model="entry.user_id"
                                            label="Id o documento (opcional)" filled></v-text-field>

                                    </v-col>

                                    <v-col cols="12" md="12" class="hidden-md-and-up">
                                        <v-btn color="success" x-large type="submit" block :loading="loading">
                                            <v-icon left>mdi-plus</v-icon>
                                            {{ entry.id ? 'Actualizar' : 'Agregar' }}
                                            {{ tab === 1 ? 'Ingreso' : 'Gasto' }}

                                        </v-btn>

                                        <v-btn color="error" x-large type="submit" block @click="resetForm"
                                            class="mt-4">
                                            <v-icon left>mdi-broom</v-icon>
                                            Limpiar
                                        </v-btn>
                                    </v-col>
                                </v-row>
                            </v-form>
                        </v-col>

                    </v-row>
                </v-tab-item>

                <v-tab-item>
                    <v-divider />

                    <v-row>
                        <v-col cols="12" md="12">
                            <!-- Enhanced Form -->
                            <v-form @submit.prevent="addEntry" ref="form2">
                                <v-row no-gutters>
                                    <v-col cols="12" class="hidden-sm-and-down">
                                        <v-toolbar>Registro de {{ tab === 1 ? 'Ingreso' : 'Gasto' }}

                                            <v-spacer></v-spacer>
                                            <v-btn color="error" @click="resetForm" :loading="loading" class="mr-2">
                                                <v-icon left>
                                                    mdi-broom
                                                </v-icon>
                                                Limpiar
                                            </v-btn>
                                            <v-btn color="success" @click="addEntry" :loading="loading">
                                                <v-icon left>mdi-plus</v-icon>
                                                {{ entry.id ? 'Actualizar' : 'Agregar' }}
                                                {{ tab === 1 ? 'Ingreso' : 'Gasto' }}
                                            </v-btn>
                                        </v-toolbar>
                                    </v-col>
                                    <v-col cols="6" md="4">

                                        <v-menu v-model="menu" :close-on-content-click="false" :nudge-right="40"
                                            transition="scale-transition" offset-y>
                                            <template v-slot:activator="{ on }">
                                                <v-text-field hide-details="" v-model="entry.date" label="Fecha"
                                                    readonly filled v-on="on"></v-text-field>
                                            </template>
                                            <v-date-picker v-model="entry.date" no-title scrollable>
                                                <template v-slot:day="{ date }">
                                                    {{ date.getDate() }}
                                                </template>
                                            </v-date-picker>
                                        </v-menu>
                                    </v-col> <v-divider vertical></v-divider>



                                    <v-col cols="6" md="4"> <v-combobox hide-details="" v-model="entry.currency"
                                            :items="currencies" label="Moneda" filled :search-input.sync="search"
                                            :rules="[rules.required]">
                                            <template v-slot:no-data>
                                                <v-list-item>
                                                    <v-list-item-content>
                                                        <v-list-item-title>
                                                            No hay resultado para "<strong>{{ search }}</strong>".
                                                            Presione
                                                            <kbd>enter</kbd> para agregar.
                                                        </v-list-item-title>
                                                    </v-list-item-content>
                                                </v-list-item>
                                            </template>

                                        </v-combobox></v-col>

                                    <v-col cols="12" md="4">
                                        <v-combobox filled hide-details="" v-model="entry.category" :items="categories"
                                            label="Categoría" :search-input.sync="search2" :rules="[rules.required]">
                                            <template v-slot:no-data>
                                                <v-list-item>
                                                    <v-list-item-content>
                                                        <v-list-item-title>
                                                            No hay resultado para "<strong>{{ search2 }}</strong>".
                                                            Presione
                                                            <kbd>enter</kbd> para agregar.
                                                        </v-list-item-title>
                                                    </v-list-item-content>

                                                </v-list-item>
                                            </template>
                                        </v-combobox>
                                    </v-col>

                                    <v-col cols="6" md="4"><v-text-field hide-details="" v-model="entry.amount"
                                            label="Cantidad" type="number" filled
                                            :rules="[rules.required, rules.positiveNumber]"></v-text-field></v-col>

                                    <v-divider vertical></v-divider>


                                    <v-col cols="6" md="4"><v-text-field hide-details="" v-model="entry.payAmount"
                                            label="Monto" type="number" prefix="$" filled
                                            :rules="[rules.required, rules.positiveNumber]"></v-text-field></v-col>

                                    <v-col cols="12" md="4"> <v-text-field hide-details="" v-model="entry.description"
                                            label="Descripción (opcional)" filled></v-text-field>
                                    </v-col>
                                    <v-col cols="12" md="4"> <v-text-field hide-details="" v-model="entry.user_id"
                                            label="Id o documento (opcional)" filled></v-text-field>

                                    </v-col>

                                    <v-col cols="12" md="12" class="hidden-md-and-up">
                                        <v-btn color="success" x-large type="submit" block :loading="loading">
                                            <v-icon left>mdi-plus</v-icon>
                                            {{ entry.id ? 'Actualizar' : 'Agregar' }}
                                            {{ tab === 1 ? 'Ingreso' : 'Gasto' }}

                                        </v-btn>

                                        <v-btn color="error" x-large type="submit" block @click="resetForm"
                                            class="mt-4">
                                            <v-icon left>mdi-broom</v-icon>
                                            Limpiar
                                        </v-btn>
                                    </v-col>
                                </v-row>
                            </v-form>
                        </v-col>
                    </v-row>
                </v-tab-item>

            </v-tabs>

        </v-card>
    </v-container>
</template>

<script>
import {
    doc, addDoc, updateDoc, collection, getFirestore, where, query, getDocs, deleteDoc, Timestamp
} from 'firebase/firestore';
import moment from 'moment';
export default {
    data: () => ({
        loading: true,
        search: null,
        search2: null,
        entry: {
            type: 'ingress',
            date: moment().format('YYYY-MM-DD'),
            category: null,
            amount: 1,
            description: null,
            user_id: null,
            payAmount: 0.0,
            currency: 'UYU',
        },
        types: [
            {
                text: 'Ingreso',
                value: 'ingress',
            },
            {
                text: 'Gasto',
                value: 'egress',
            }
        ],
        entries: [],
        filter: {
            startDate: '',
            endDate: '',
        },
        headers: [
            { text: 'Tipo', value: 'type' },
            { text: 'Fecha', value: 'date' },
            { text: 'Categoría', value: 'category' },
            { text: 'Cantidad', value: 'amount' },
            { text: 'Descripción', value: 'description' },
            { text: 'Cédula', value: 'user_id' },
            { text: 'Monto', value: 'payAmount' },
            { text: 'Moneda', value: 'currency' },
            { text: 'Acciones', value: 'actions', sortable: false }
        ],
        categories: ['Matricula', 'Agua', 'GATORADE', "ALFAPRO", "D3"],
        currencies: ['UYU', 'USD'],
        rules: {
            required: value => !!value || 'Requerido.',
            positiveNumber: value => value > 0 || 'Must be positive.',
        },
        menu: false,
        menu2: false,
        tab: 0,
        menu3: false,
        dates: [],


    }),

    methods: {
        async addEntry() {

            if (this.entry.type == 'ingress') {
                if (!this.$refs.form.validate()) {
                    return
                }
            }

            if (this.entry.type == 'egress') {
                if (!this.$refs.form2.validate()) {
                    return
                }
            }

            // Implement your logic to add the entry to your backend/database here
            try {
                this.loading = true;
                const db = getFirestore();

                if (this.entry.id) {
                    await updateDoc(doc(db, 'ingress-egress', this.entry.id), {
                        ...this.entry,
                        date: Timestamp.fromDate(moment(this.entry.date, 'YYYY-MM-DD').toDate()),
                        amount: parseFloat(this.entry.amount),
                        payAmount: parseFloat(this.entry.payAmount),
                        processedBy: this.$store.state.Auth.token.claims.user_id,
                    });
                    this.$notify({
                        group: 'feedback',
                        type: 'success',
                        title: 'Registro actualizado',
                        text: 'El registro se ha actualizado correctamente',
                    });
                    // update the entry locally
                    const index = this.entries.findIndex(entry => entry.id === this.entry.id);
                    if (index !== -1) {
                        this.entry.date = Timestamp.fromDate(moment(this.entry.date, 'YYYY-MM-DD').toDate())
                        this.entries[index] = { ...this.entry };

                        this.fetchRecords();
                    }
                } else {

                    const docRef = await addDoc(collection(db, 'ingress-egress'), {
                        ...this.entry,
                        date: Timestamp.fromDate(moment(this.entry.date, 'YYYY-MM-DD').toDate()),
                        amount: parseFloat(this.entry.amount),
                        payAmount: parseFloat(this.entry.payAmount),
                        processedBy: this.$store.state.Auth.token.claims.user_id,
                    });

                    this.entry.id = docRef.id;
                    this.entry.date = Timestamp.fromDate(moment(this.entry.date, 'YYYY-MM-DD').toDate())
                    this.entries.push({ ...this.entry });


                    this.$notify({
                        group: 'feedback',
                        type: 'success',
                        title: 'Registro agregado',
                        text: 'El registro se ha agregado correctamente',
                    });
                }



                this.entry.date = moment().format('YYYY-MM-DD');
                this.entry.id = null;
                this.resetForm();

            } catch (error) {
                console.log(error);
                this.$notify({
                    group: 'feedback',
                    type: 'error',
                    title: 'Error',
                    text: 'Ha ocurrido un error al agregar el registro',
                });
            } finally {

                this.loading = false;
            }

        },
        async fetchRecords() {
            try {
                this.loading = true;
                const db = getFirestore();
                let start, end;

                if (this.dates.length == 2) {
                    start = new Date(this.dates[0] + " 00:00:00")
                    end = new Date(this.dates[1] + " 23:59:59")
                } else {
                    // here start is the start of the month and end is today at 23:59:59
                    let date = moment()
                    let startOfMonth = moment().startOf('month')

                    start = new Date(startOfMonth.format('YYYY-MM-DD') + " 00:00:00")
                    end = new Date(date.format('YYYY-MM-DD') + " 23:59:59")

                    this.dates = [startOfMonth.format('YYYY-MM-DD'), date.format('YYYY-MM-DD')]
                }

                let q;

                q = query(
                    collection(db, 'ingress-egress'),
                    where('date', '>=', start),
                    where('date', '<=', end)
                );

                const querySnapshot = await getDocs(q);

                this.entries = querySnapshot.docs.map(doc => {
                    return { id: doc.id, ...doc.data() };
                });

            } catch (error) {
                this.$notify({
                    group: 'feedback',
                    type: 'error',
                    title: 'Error',
                    text: 'Ha ocurrido un error al cargar los registros',
                });
            } finally {
                this.loading = false;
            }
        },
        resetForm() {
            if (this.$refs.form)
                this.$refs.form.reset();
            if (this.$refs.form2)
                this.$refs.form2.reset();

            this.entry = {
                type: 'ingress',
                date: moment().format('YYYY-MM-DD'),
                category: null,
                amount: 1,
                description: null,
                user_id: null,
                payAmount: 0.0,
                currency: 'UYU',
            };

            this.menu = false;


        },
        async deleteEntry(id) {
            try {

                const confirm = await this.$confirm(
                    "Seguro que quieres eliminar este registro?",
                    {
                        color: "error", title: "Eliminar registro",
                        buttonTrueText: "CONFIRMAR",
                        buttonFalseText: "CANCELAR"
                    }
                );

                if (!confirm) {
                    return;
                }


                this.loading = true;
                const db = getFirestore();
                await deleteDoc(doc(db, 'ingress-egress', id));
                this.$notify({
                    group: 'feedback',
                    type: 'success',
                    title: 'Registro eliminado',
                    text: 'El registro se ha eliminado correctamente',
                });
                // now remove locally
                this.entries = this.entries.filter(entry => entry.id !== id);
            } catch (error) {
                console.log(error);
                this.$notify({
                    group: 'feedback',
                    type: 'error',
                    title: 'Error',
                    text: 'Ha ocurrido un error al eliminar el registro',
                });
            } finally {
                this.loading = false;
            }
        },
        editEntry(entry) {
            this.entry = {
                ...entry,
                date: moment(entry.date.toDate()).format('YYYY-MM-DD'),
            };
            this.menu = false;
            this.menu2 = false;

            if (this.entry.type == 'ingress') {
                this.tab = 1;
            } else {
                this.tab = 2;
            }

        },
        downloadCSVReport() {

            const headers = ['Tipo', 'Fecha', 'Categoría', 'Cantidad', 'Descripción', 'Cédula', 'Monto', 'Moneda'];
            const csv = this.entries.map(entry => {
                return `${entry.type == 'ingress' ? 'ingreso' : 'egreso'},${moment(new Date(entry.date.seconds * 1000)).format("YYYY-MM-DD")},${entry.category},${entry.amount},${entry.description},${entry.user_id},${entry.payAmount},${entry.currency}`;
            }).join('\n');


            csv = headers.join(',') + '\n' + csv;

            const blob = new Blob([csv], { type: 'text/csv' });
            const url = window.URL.createObjectURL(blob);
            const a = document.createElement('a');
            a.href = url;
            a.download = `ingresos-egresos-${this.dates[0]}-${this.dates[1]}.csv`;
            a.click();
        }
    },

    mounted() {
        // Fetch records on component mount
        this.fetchRecords();
    },

    watch: {
        tab(val) {
            if (val === 1) {
                this.categories = ['Clase Aislada', 'Pase Libre', 'Alquiler Domo', 'Evaluación', 'Malatic', 'Merchandising Sportfit', 'BareRecovery', 'Suplementos', 'Personalizados Docentes', 'Otros'].sort();
                this.entry.type = 'ingress';
            } else if (val === 2) {
                this.categories = ['Sueldos', 'Alquiler', 'Luz', 'Agua(servicio)', 'Internet', 'Teléfono', 'Insumos de limpieza', 'Insumos de oficina', 'Insumos de cocina', 'Insumos de mantenimiento', 'Gastos materiales', 'Gastos varios', 'Proveedores', 'Recoleccion de residuos', 'Retenciones', 'Deuda', 'Fisio'];
                this.entry.type = 'egress';
            }
        }
    }
};
</script>

<style>
.v-date-picker-table .v-btn.v-btn--active {
    color: #000 !important;
}

.v-application--is-ltr .v-data-footer__pagination {
    margin: 0 8px 0 12px !important;
}
</style>

<style lang="scss" scoped>
.v-tab {
    text-transform: none;
    letter-spacing: normal;
}
</style>